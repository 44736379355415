package ru.astrainteractive.web.feature.main.ui

import androidx.compose.runtime.Composable
import empiresmp.components.Spacer
import empiresmp.components.layouts.PageLayout
import empiresmp.components.navbar.AstraNavBar
import empiresmp.feature.main.ui.component.MainPagerModelContent
import ru.astrainteractive.web.feature.main.model.MainPagerModels
import ru.astrainteractive.web.feature.main.ui.components.AstraLearnerPagerModel
import ru.astrainteractive.web.feature.main.ui.components.EmpireProjektPagerModel
import ru.astrainteractive.web.feature.main.ui.components.MainTitlePage

@Composable
fun MainPageContent() {
    PageLayout {
        AstraNavBar()
        MainTitlePage()
        Spacer()
        AstraLearnerPagerModel()
        Spacer()
        EmpireProjektPagerModel()
        Spacer()
        MainPagerModelContent(MainPagerModels.MinecraftPlugins)
    }
}
