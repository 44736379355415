package empiresmp.components

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.size
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.navigation.Anchor
import com.varabyte.kobweb.silk.components.graphics.Image
import org.jetbrains.compose.web.dom.Text
import ru.astrainteractive.kobwebx.core.theme.composable.LocalSitePalette

@Composable
fun TextLink(
    path: String,
    text: String,
    modifier: Modifier = Modifier,
) {
    Anchor(
        href = path,
        attrs = modifier.toAttrs(),
        content = { Text(text) }
    )
}

@Composable
fun ImageTextLink(
    path: String,
    text: String,
    iconPath: String,
    modifier: Modifier = Modifier
) {
    val theme = LocalSitePalette.current
    Anchor(
        href = path,
        attrs = modifier.toAttrs(),
        content = {
            Column(
                horizontalAlignment = Alignment.CenterHorizontally,
                verticalArrangement = Arrangement.Center
            ) {
                Image(iconPath, modifier = Modifier.size(theme.dimens.XL))
                Text(text)
            }
        }
    )
}
