package empiresmp.components.navbar

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxHeight
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.onClick
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.style.toModifier
import empiresmp.components.TextLink
import empiresmp.components.ThemeButton
import ru.astrainteractive.kobwebx.core.theme.composable.LocalSitePalette
import ru.astrainteractive.kobwebx.core.theme.presentation.ThemeKrate
import ru.astrainteractive.kobwebx.core.theme.util.ThemeExt.typography

@Composable
fun AstraNavBar() {
    val theme = LocalSitePalette.current
    NavBar {
        Image("favicon.ico", modifier = Modifier.padding(theme.dimens.L).onClick { ThemeKrate.next() })
        Row(
            modifier = Modifier.fillMaxHeight().fillMaxWidth(),
            verticalAlignment = Alignment.CenterVertically,
            horizontalArrangement = Arrangement.End
        ) {
            TextLink(
                path = "/",
                text = "HOME",
                modifier = theme.typography.body2.toModifier()
            )
            TextLink(
                "https://github.com/orgs/Astra-Interactive",
                "GITHUB",
                modifier = theme.typography.body2.toModifier()
            )
        }
        ThemeButton()
    }
}
