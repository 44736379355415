@file:Suppress("MaxLineLength")

package ru.astrainteractive.web.feature.main.ui.components

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.maxWidth
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.textAlign
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.silk.components.style.toModifier
import com.varabyte.kobweb.silk.components.text.SpanText
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import ru.astrainteractive.kobwebx.core.theme.composable.LocalSitePalette
import ru.astrainteractive.kobwebx.core.theme.util.ThemeExt.typography

@Composable
fun MainTitlePage() {
    val theme = LocalSitePalette.current
    Box(
        modifier = Modifier.width(100.percent),
        contentAlignment = Alignment.Center,
    ) {
        Column(
            Modifier
                .maxWidth(90.percent)
                .padding(64.px),
            horizontalAlignment = Alignment.CenterHorizontally,
            verticalArrangement = Arrangement.Center
        ) {
            SpanText(
                text = "CREATING GREAT STUFF",
                modifier = theme.typography.h2.toModifier()
            )
            SpanText(
                text = "Astra Interactive made mobile applications, Kotlin libraries and Minecraft plugins",
                modifier = theme.typography.body2.toModifier().then(Modifier.textAlign(TextAlign.Center))
            )
            HrefFilledButton(
                href = "https://github.com/Astra-Interactive",
                modifier = Modifier.margin(theme.dimens.L),
                text = "Astra: GitHub"
            )
            SpanText(
                text = "Polishing every aspect of our projects",
                modifier = theme.typography.body2.toModifier().then(Modifier.textAlign(TextAlign.Center))
            )
        }
    }
}
