package ru.astrainteractive.web.feature.main.model

import empiresmp.feature.main.presentation.model.MainPagerModel
import empiresmp.feature.main.presentation.model.MainPagerModelDirection
import ru.astrainteractive.web.feature.main.resources.MainImagesR

object MainPagerModels {
    data object AstraLearner : MainPagerModel(
        coloredTitle = "Learn words faster",
        backgroundColor = { theme -> theme.colors.colorOnPrimary }, // todo
        titleColor = { theme -> theme.colors.colorPrimary }, // todo
        title = "What is Astra Learner?",
        text = listOf(
            "Astra Learner will help to learn foreign words.",
            "Having multiple learn modes you will remember words faster.",
            "Use Mobile or Desktop application to create your own dictionaries"
        ),
        direction = MainPagerModelDirection.LTR,
        resource = MainImagesR.astralearner
    )

    data object EmpireProjektMobile : MainPagerModel(
        coloredTitle = "Server Mobile App",
        backgroundColor = { theme -> theme.colors.colorOnPrimary }, // todo
        titleColor = { theme -> theme.colors.colorPrimary }, // todo
        title = "EmpireProjekt.ru",
        text = listOf(
            "EmpireProjekt is an old Minecraft Server. It tries so hard to become better, yet it still not ideal.",
            "This mobile applicatioin allows players to be invoked with social integrations beyond game!"
        ),
        direction = MainPagerModelDirection.RTL,
        resource = MainImagesR.empiremobile
    )

    data object MinecraftPlugins : MainPagerModel(
        coloredTitle = "Minecraft Plugins",
        backgroundColor = { theme -> theme.colors.colorOnPrimary }, // todo
        titleColor = { theme -> theme.colors.colorPrimary }, // todo
        title = "Get unique plugins for yourself!",
        text = listOf(
            "Astra Interactive created a lot of plugins for minecraft:",
            "Rating, AspeKt, Market, Dynamic Shop..",
            "We're also maintaining some old plugins!",
            "Don't forget to check Template Plugin and AstraLibs!"
        ),
        direction = MainPagerModelDirection.LTR,
        resource = MainImagesR.pitha
    )
}
