package empiresmp.pages

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.core.Page
import ru.astrainteractive.web.feature.main.ui.MainPageContent

@Page
@Composable
fun Index() {
    MainPageContent()
}
