package ru.astrainteractive.web.feature.main.ui.components

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.TextDecorationLine
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.textDecorationLine
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.navigation.Anchor
import empiresmp.components.FilledText
import org.jetbrains.compose.web.css.Color
import org.jetbrains.compose.web.css.px
import ru.astrainteractive.kobwebx.core.theme.composable.LocalSitePalette

@Composable
fun HrefFilledButton(
    href: String,
    text: String,
    modifier: Modifier = Modifier
) {
    val theme = LocalSitePalette.current
    Anchor(
        href = href,
        attrs = modifier.textDecorationLine(TextDecorationLine.None).toAttrs(),
        content = {
            Box(
                modifier = Modifier
                    .backgroundColor(theme.colors.colorOnPrimary)
                    .borderRadius(theme.dimens.XS)
                    .padding(topBottom = 14.px, leftRight = 32.px),
                contentAlignment = Alignment.Center,
                content = {
                    FilledText(
                        text = text,
                        fillColor = Color.transparent,
                        textColor = theme.colors.colorPrimary,
                        borderRadius = theme.dimens.S,
                        modifier = Modifier.padding(theme.dimens.L)
                    )
                }
            )
        }
    )
}
