package ru.astrainteractive.web.feature.main.ui.components

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.background
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.silk.components.style.toModifier
import empiresmp.components.TextLink
import empiresmp.feature.main.ui.component.MainPagerModelContent
import ru.astrainteractive.kobwebx.core.theme.composable.LocalSitePalette
import ru.astrainteractive.kobwebx.core.theme.util.ThemeExt.typography
import ru.astrainteractive.web.feature.main.model.MainPagerModels

@Composable
fun AstraLearnerPagerModel() {
    val theme = LocalSitePalette.current
    MainPagerModelContent(MainPagerModels.AstraLearner) {
        Box(Modifier.padding(topBottom = theme.dimens.M))
        Row(
            horizontalArrangement = Arrangement.SpaceBetween,
            verticalAlignment = Alignment.CenterVertically,
            modifier = Modifier.fillMaxWidth()
        ) {
            TextLink(
                path = "/astralearner-latest.jar",
                text = "Desktop APP",
                modifier = theme.typography.h5.toModifier()
                    .borderRadius(theme.dimens.XS)
                    .background(theme.colors.colorSecondaryVariant)
                    .color(theme.colors.colorOnSecondaryVariant)
            )
            TextLink(
                path = "https://play.google.com/store/apps/details?id=com.makeevrserg.astralearner",
                text = "Android APP",
                modifier = theme.typography.h5.toModifier()
                    .borderRadius(theme.dimens.XS)
                    .background(theme.colors.colorSecondary)
                    .color(theme.colors.colorOnSecondary)
            )
        }
    }
}
